<template>
  <div class="edu-frame">
    <edu-nav-bar title="填写心得" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>

    <div :style="comment.isSubmitComment == 1 ? 'height: calc(100vh - 46px - 20px)' : ''" style="width: 96%;margin: 10px auto;background-color: white;height: calc(100vh - 46px - 20px - 44px);overflow: auto;">
      <div class="class-comment">
        <div style="font-size: 14px;color: gray;margin-top: 10px;">活动名称</div>
        <van-field
            :readonly="comment.isSubmitComment==1"
            v-model="comment.activityName"
            label-width="0px"
            placeholder="活动名称"
            label-align="top"
            autosize
        />

        <div style="margin-top: 10px;font-size: 14px;color: gray;">活动流程/环节有哪些？</div>
        <van-field
            :readonly="comment.isSubmitComment==1"
            v-model="comment.commentFlow"
            label-width="0px"
            placeholder="活动流程/环节有哪些？"
            label-align="top"
            rows="3"
            autosize
            type="textarea"
            maxlength="100"
            show-word-limit
        />

        <div style="margin-top: 10px;font-size: 14px;color: gray;">本活动在哪里开展？</div>
        <van-field :readonly="comment.isSubmitComment==1" v-model="comment.commentAddress" label-width="0px" placeholder="本活动在哪里开展？" label-align="top" maxlength="100"/>

        <div style="display: flex;align-items: center;height: 50px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">本活动共有多少个家长参加？</div>
          <van-stepper :disabled="comment.isSubmitComment==1" v-model="comment.commentParentCount" :min="0"/>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>

        <div style="display: flex;align-items: center;height: 50px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">本活动共有多少个孩子参加？</div>
          <van-stepper :disabled="comment.isSubmitComment==1" v-model="comment.commentChildCount" :min="0"/>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>


        <div style="display: flex;align-items: center;height: 100px;">
          <div style="font-size: 14px;color: gray;flex: 1;height: 50px;display: flex;align-items: center;">本活动孩子年龄范围？</div>
          <div style="display: flex;flex-direction: column;align-items: center;">
            <van-stepper :disabled="comment.isSubmitComment==1" v-model="comment.commentMinAge" :min="0"/>
            <div style="margin: 5px 0px;font-size: 14px;">至</div>
            <van-stepper :disabled="comment.isSubmitComment==1" v-model="comment.commentMaxAge" :min="0"/>
          </div>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>


        <div style="margin-top: 10px;font-size: 14px;color: gray;">写下孩子们的活动反馈或者你的感想吧！</div>
        <van-field
            :readonly="comment.isSubmitComment==1"
            v-model="comment.comment"
            label-width="0px"
            placeholder="写下孩子们的活动反馈或者你的感想吧！"
            label-align="top"
            rows="3"
            autosize
            type="textarea"
            maxlength="100"
            show-word-limit
        />

        <div style="display: flex;align-items: center;margin-top: 10px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">活动精彩照片</div>
        </div>
        <van-uploader :disabled="comment.isSubmitComment==1" v-model="fileList" multiple :max-count="9" style="margin-top: 10px;" :after-read="changeFile" @delete="changeFile"/>
      </div>

    </div>

    <van-button v-if="comment.isSubmitComment != 1" :loading="submitting" square block type="primary" @click="submitComment">保存</van-button>
  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import {Button, Field, Uploader, Stepper} from "vant";
import Tools from "@/api/Tools";
import ActivityApi from "@/api/ActivityApi";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanField: Field,
    VanUploader: Uploader,
    VanStepper: Stepper
    /*VanCheckbox: Checkbox*/
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      cust: Tools.getCurCust(),
      comment: {},
      fileList: [],
      files: [],
      deleteFileIDs: [],
      submitting:false
    }
  },
  methods: {
    submitComment() {
      if (this.submitting) {
        return
      }

      this.$dialog.confirm({title: '注意', message: '心得提交后无法修改，请确认后再提交。'}).then(() => {

        for (var i = 0; i < 9; i++) {
          this.comment['pic'+(i+1)] = ''
          if (i < this.files.length) {
            this.comment['pic'+(i+1)] = this.files[i].base64img
          }
        }
        this.comment.custId = this.cust.custId
        console.log('comments： ==', this.comment)
        this.submitting = true
        ActivityApi.submitVolunteerComment(this.comment).then(response => {
          this.submitting = false
          this.$dialog.alert({message: response.msg})
          if (response.code == 100) {
            this.$router.back()
          }
        }).catch(() => { this.submitting = false })

      }).catch(() => { this.submitting = false })

    },
    changeFile(e) {
      console.log('e.url == ', e)
      if (e.url != null) {
        var fileID = null;
        var fileIndex = -1
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].base64img == e.url) {
            fileID = this.files[i].fileID
            fileIndex = i
            break
          }
        }
        console.log('fileIndex ' + fileIndex)
        if (fileIndex != null) {
          this.files.splice(fileIndex, 1)
          this.deleteFileIDs.push(fileID)
        }
      } else {
        var self = this
        self.files = []
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].file != null) {
            // this.files.push({ base64img: this.fileList[i].content, fileName: this.fileList[i].file.name })
            Tools.compressFile(this.fileList[i].file).then(response=> {
              console.log('file response', response)
              Tools.uploadFileByBase64(response, (base64Response)=>{
                // console.log(base64Response)
                self.files.push({ base64img: base64Response, fileName: response.name })
              })
            })
          }
        }

      }
      // this.changeFileList()
    },
    getVolunteerCommentRptById() {
      var commentId = this.$route.query.commentId
      if (commentId != null) {
        ActivityApi.getVolunteerCommentRptById(commentId).then(response => {
          this.comment = response.res
          console.log('response == ', response)
          for (var i = 1; i < 9; i++) {
            if (this.comment['pic' + i] != null && this.comment['pic' + i] != '') {
              this.files.push({ base64img: window.location.href.substring(0, window.location.href.indexOf('#/')) + this.comment['pic' + i] })
              this.fileList.push({ url: window.location.href.substring(0, window.location.href.indexOf('#/')) + this.comment['pic' + i] })
            }
          }
          console.log('this.fileList == ', this.fileList)
        })
      }
    }
  },
  mounted() {
    this.getVolunteerCommentRptById()
  }
}
</script>
<style scoped>
.class-comment {
  width: 95%;
  margin: 0 auto;
}
</style>

